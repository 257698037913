import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { resources } from './translation'

export const supportedLngs = {
  en: 'English',
  ru: 'Русский',
}

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  supportedLngs: Object.keys(supportedLngs),
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
