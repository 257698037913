export const resources = {
  en: {
    translation: {
      main: 'Main',
      dashboard: 'Dashboard',
      users: 'Users',
      categories: 'Categories',
      promotions: 'Promotions',
      companies: 'Tenants',
      banners: 'Banners',
      news: 'News',
      parking: 'Parking',
      roles: 'Roles',
      campaigns: 'Campaigns',
      topbrands: 'Brand carousel',
      out: 'Logout',
      login: 'Login',
      show: 'Show',
      verification: 'Verification',
      yes: 'Yes',
      no: 'No',
      totalUsers: 'Total users',
      totalPromos: 'Total promotions',
      totalNews: 'Total news',
      totalCategories: 'Total categories',
      totalOrganizations: 'Total tenants',
      totalBanners: 'Total banners',
      confirmedTenant: 'Confirmed tenant',
      email: 'Email',
      surname: 'Surname',
      name: 'Name',
      patronymic: 'Patronymic',
      all: 'all',
      admin: 'administrator',
      moderator: 'moderator',
      companyadmin: 'company admin',
      employee: 'employee',
      user: 'user',
      tenant: 'tenant',
      phone: 'Phone',
      inn: 'TIN',
      city: 'City',
      activated: 'Activated',
      lastVisit: 'Last visit',
      lastActivity: 'Last activity',
      regDate: 'Registration date',
      confirmed: 'Confirmed',
      notConfirmed: 'Not confirmed',
      perPage: 'Show on page',
      id: 'ID',
      userData: 'User data',
      children: 'Children',
      age: 'Age',
      active: 'Active',
      save: 'Save',
      parentId: 'Parent Id',
      parentCategory: 'Parent category',
      title: 'Title',
      categoryActive: 'Active',
      picture: 'Picture',
      createdDate: 'Created date',
      preview: 'Preview',
      updateUserMsg: 'User is updated',
      updateUserErrMsg: 'User update error',
      updateCategoryMsg: 'Category is updated',
      updateCategoryErrMsg: 'Category update error',
      changeCategory: 'Change category',
      wrongLogin: 'Wrong login or password',
      loginAcc: 'Login to your account',
      loginPlaceholder: 'Login',
      password: 'Password',
      description: 'Description',
      from: 'From',
      to: 'To',
      promotionCreateMsg: 'Promotion updated',
      promotionErrMsg: 'Promotion error',
      company: 'Company',
      companyID: 'Company ID',
      activeFrom: 'Active from',
      activeTo: 'Active to',
      updateDate: 'Update date',
      detailImg: 'Detailed image',
      image: 'Image',
      companyMsg: 'Company updated',
      companyErrMsg: 'Company error',
      site: 'Site',
      openTime: 'Open time',
      logo: 'Logo',
      mainImage: 'Main image',
      createBanner: 'Create banner',
      startDate: 'Start date',
      endDate: 'End date',
      bannerLayout: 'Banner layout',
      cancel: 'Cancel',
      top: 'Top',
      bottom: 'Bottom',
      middle: 'Middle',
      middleHalf: 'Middle (half)',
      bannerMsg: 'Banner updated',
      bannerErrMsg: 'Banner error',
      media: 'Media',
      changeBanner: 'Change banner',
      newsMsg: 'News updated',
      newsErrMsg: 'News error',
      imageDetails: 'Image details',
      imageText: 'Image text',
      displayName: 'Display name',
      createdAt: 'Creation date',
      updatedAt: 'Update date',
      status: 'Status',
      platform: 'Platform',
      sends: 'Sends',
      finished: 'Finished',
      draft: 'Draft',
      scheduled: 'Scheduled',
      unknown: 'Unknown',
      sended: 'Sended',
      sendedErr: 'Sended error',
      companyDeleteMsg: 'Company is deleted',
      newCompany: 'New company',
      send: 'Send',
      edit: 'Edit',
      delete: 'Delete',
      notification: 'Notification',
      heading: 'Heading',
      text: 'Text',
      imgUrl: 'Image url',
      next: 'Next',
      selectUser: 'Select user by',
      any: 'Any',
      conditions: 'Conditions',
      addCondition: 'Add condition',
      conditionMsg1: '% potential users meet the specified conditions:',
      conditionMsg2: 'The assessment is based on',
      conditionMsg3:
        "registered users. Some users won't see the notification because the device is inactive.",
      greater: 'Greater',
      less: 'Less',
      equal: 'Equal',
      brandOrder: 'Brand display order',
      addBrand: 'Add brand to carousel',
      selectCompany: 'Select company',
      selectOrder: 'Select order',
    },
  },
  ru: {
    translation: {
      main: 'Главная',
      dashboard: 'Дэшборд',
      users: 'Пользователи',
      categories: 'Категории',
      promotions: 'Акции',
      companies: 'Арендаторы',
      banners: 'Баннеры',
      news: 'Новости',
      parking: 'Парковка',
      roles: 'Роли',
      campaigns: 'Компании',
      topbrands: 'Карусель брендов',
      out: 'Выйти',
      login: 'Войти',
      show: 'Посмотреть',
      verification: 'Подтверждение',
      yes: 'Да',
      no: 'Нет',
      totalUsers: 'Всего пользователей',
      totalPromos: 'Всего акций',
      totalNews: 'Всего новостей',
      totalCategories: 'Всего категорий',
      totalOrganizations: 'Всего арендаторов',
      totalBanners: 'Всего баннеров',
      confirmedTenant: 'Подтвержденный арендатор',
      email: 'Эл. почта',
      surname: 'Фамилия',
      name: 'Имя',
      patronymic: 'Отчество',
      all: 'все',
      admin: 'администратор',
      moderator: 'модератор',
      companyadmin: 'администратор компании',
      employee: 'сотрудник компании',
      user: 'пользователь',
      tenant: 'арендатор',
      phone: 'Телефон',
      inn: 'ИНН',
      city: 'Город',
      activated: 'Активирован',
      lastVisit: 'Последний вход',
      lastActivity: 'Последнее действие',
      regDate: 'Дата регистрации',
      confirmed: 'Подтвержден',
      notConfirmed: 'Не подтвержден',
      perPage: 'Показывать на странице',
      id: 'Идентификатор',
      userData: 'Данные пользователя',
      children: 'Детей',
      age: 'Возраст',
      active: 'Активен',
      save: 'Сохранить',
      parentId: 'Id род. категории',
      parentCategory: 'Родительская категория',
      title: 'Название',
      categoryActive: 'Активна',
      picture: 'Изображение',
      createdDate: 'Дата создания',
      preview: 'Предпросмотр',
      updateUserMsg: 'Пользователь обновлен',
      updateUserErrMsg: 'Ошибка при обновлении пользователя',
      updateCategoryMsg: 'Категория обновлена',
      updateCategoryErrMsg: 'Ошибка при обновлении категории',
      changeCategory: 'Изменить категорию',
      wrongLogin: 'Неверный логин или пароль',
      loginAcc: 'Войдите в свой аккаунт',
      loginPlaceholder: 'Пользователь',
      password: 'Пароль',
      description: 'Описание',
      from: 'От',
      to: 'До',
      promotionCreateMsg: 'Акция успешно обновлена!',
      promotionErrMsg: 'Произошла ошибка при обновлении акции!',
      company: ' Company',
      companyID: 'Идентификатор Компании',
      activeFrom: 'Активен от',
      activeTo: 'Активен до',
      updateDate: 'Дата обновления',
      detailImg: 'Подробное изображение',
      image: 'Изображение',
      companyMsg: 'Компания успешно обновлена!',
      companyErrMsg: 'Произошла ошибка при обновлении компании!',
      site: 'Сайт',
      openTime: 'Часы работы',
      logo: 'Лого',
      mainImage: 'Оссновное изображение',
      createBanner: 'Создать баннер',
      startDate: 'Дата начала',
      endDate: 'Дата окончания',
      bannerLayout: 'Расположение баннера',
      cancel: 'Отмена',
      top: 'Сверху',
      bottom: 'Снизу',
      middle: 'Посередине',
      middleHalf: 'Посередине (половина)',
      bannerMsg: 'Баннер успешно обновлена!',
      bannerErrMsg: 'Произошла ошибка при обновлении баннер!',
      media: 'Медиа',
      changeBanner: 'Изменить баннер',
      newsMsg: 'Новость успешно обновлена!',
      newsErrMsg: 'Произошла ошибка при обновлении новости!',
      imageDetails: 'Детали изображения',
      imageText: 'Текст изображения',
      displayName: 'Отображаемое имя',
      createdAt: 'Создан',
      updatedAt: 'Обновлен',
      status: 'Статус',
      platform: 'Платформа',
      sends: 'Отправок',
      finished: 'Завершена',
      draft: 'Черновик',
      scheduled: 'Запланирована',
      unknown: 'Неизвесто',
      sended: 'Отправлено',
      sendedErr: 'Ошибка отправки',
      companyDeleteMsg: 'Кампания успешно удалена',
      newCompany: 'Новая компания',
      send: 'Отправить',
      edit: 'Редактировать',
      delete: 'Удалить',
      notification: 'Уведомление',
      heading: 'Заголовок',
      text: 'Текст',
      imgUrl: 'Ссылка на изображение',
      next: 'Далее',
      selectUser: 'Выбирать пользователей по',
      any: 'Любым',
      conditions: 'Условиям',
      addCondition: 'Добавить условие',
      conditionMsg1:
        '% потенциальных пользователей подходят по заданным условиям:',
      conditionMsg2: 'Оценка строится на основе',
      conditionMsg3:
        'зарегистрированных пользователях. Некоторые пользователи не увидят уведомление из-за неактивности устройства.',
      greater: 'Больше',
      less: 'Меньше',
      equal: 'Равно',
      brandOrder: 'Порядок вывода брендов',
      addBrand: 'Добавить бренд в карусель',
      selectCompany: 'Выбрать компанию',
      selectOrder: 'Выбрать порядок',
    },
  },
}
